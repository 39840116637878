import React from 'react';
import { Provider } from 'react-redux';
import { render } from 'react-dom';
import store from 'store'
import history from 'routes/history';
import { Router, Route } from 'react-router-dom';


import Header from 'components/Header';
import Home from 'components/Home';
import ToastContainer from './components/common/toast/ToastContainer';
import MyLeaguesHome from 'components/my-leagues/MyLeaguesHome';
import PlayersHome from 'components/players/PlayersHome';
import Footer from './components/Footer';
import UsersHome from './components/users/UsersHome';
import DefensesHome from './components/defenses/DefensesHome';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';
import 'css/main.css';



const app = document.getElementById('root');

render(
    <Provider store={store}>
        <div style={{ height: '100%' }}>
            <Header />
            <Router history={history}>
                <div style={{ height: '100%' }}>
                    <Route exact path='/' component={PlayersHome} />
                    <Route path='/manage-users' component={UsersHome} />
                    <Route path='/players' component={PlayersHome} />
                    <Route path='/defenses' component={DefensesHome} />
                    <Route path='/my-leagues' component={MyLeaguesHome} />
                </div>
            </Router>
            <Footer />
            <ToastContainer />
        </div>
    </Provider>
    , app);