import React from 'react';
import { connect } from 'react-redux';
import history from 'routes/history';
import { Card, Button, Row, Col, ListGroup, ListGroupItem } from 'react-bootstrap';
import { checkAuthStatus } from 'actions/userActions';
import './Home.css';

const mapStoreStateToProps = (store) => {
    return {
        authenticated: store.user.user,
        user: store.user.authenticated
    };
}

class Home extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user: null,
            authenticated: false
        };
    }

    componentWillReceiveProps(props) {
        const { user, authenticated } = props;
        this.setState({ ...this.state, user, authenticated });
    }

    componentDidMount() {
        this.props.dispatch(checkAuthStatus());
        window.onresize = (e) => {
            this.setState({ ...this.state, resized: new Date() });
        }
    }

    render() {
        const { user, authenticated } = this.state;
        return (
            <div className="mr-2 ml-2 mt-5" style={{ overflowX: 'hidden' }}>
                <div className="mt-2 ml-2">
                    <Row>
                        <Col xs="12" className="home-text"><h2><i className="mdi mdi-football mr-1" /> FF Source</h2></Col>
                        <Col xs="12" sm="5" md="4" className="home-text">
                            <p>
                                View NFL player stats, intuitive charts, gamelogs and more!
                            </p>
                        </Col>
                        <Col xs="12" sm="6" md="6" style={{ marginRight: '0px' }}>
                            <Card className="app-card shadow mt-2">
                                <Card.Body>
                                    <Card.Img variant="top" src="/image/ff_source2.png" />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={{ span: 6, offset: 4 }}>
                            <Button variant="light" onClick={e => history.push('/players')}>
                                 Get Started <i className="fa fa-angle-right ml-2" />
                            </Button> 
                        </Col>
                    </Row>
                </div>
            </div>
        )

    }
}

export default connect(mapStoreStateToProps)(Home)