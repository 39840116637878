
import axios from 'axios';

export function checkAuthStatus() {
  return function(dispatch) {
    dispatch({type: 'CHECK_AUTH_STATUS'});
  }
}

export function getAuthStatus() {
    return function(dispatch) {
      axios.get(`/auth/status`)
        .then(response => {
          dispatch({type: 'SET_AUTH_STATUS_OK', payload: response.data });
        })
        .catch(err => {
          dispatch({type: 'SET_AUTH_STATUS_ERR', payload: err });
        });
    }
}

export function submitRegistration(userRegistration) {
  return function(dispatch) {
    axios.post(`/api/user/register`, userRegistration)
      .then(response => {
        dispatch({type: 'SUBMIT_USER_REGISTRATION_OK', payload: response.data });
      })
      .catch(err => {
        dispatch({type: 'SUBMIT_USER_REGISTRATION_ERR', payload: err.response.data });
      });
  }
}

export function checkUserRegistration(id) {
  return function(dispatch) {
    axios.get(`/api/user/register/check/${id}`)
      .then(response => {
        dispatch({type: 'REGISTRATION_CHECK_SUCCESS', payload: response.data });
      })
      .catch(error => {
        dispatch({type: 'REGISTRATION_CHECK_ERROR', payload: error.response.data });
      });
  }
}

export function verifyUserRegistration(verification) {
  return function(dispatch) {
    axios.post(`/api/user/verify/${verification.id}`, verification)
      .then(response => {
        dispatch({type: 'REGISTRATION_VERIFY_SUCCESS', payload: response.data });
      })
      .catch(error => {
        dispatch({type: 'REGISTRATION_VERIFY_ERROR', payload: error });
      });
  }
}

export function verifyRecaptcha(response) {
  return function(dispatch) {
    axios.post(`/api/recaptcha/verify`, { response })
      .then(response => {
        dispatch({type: 'RECAPTCHA_VERIFY_RESPONSE_SUCCESS', payload: response.data });
      })
      .catch(error => {
        dispatch({type: 'RECAPTCHA_VERIFY_RESPONSE_ERROR', payload: error });
      });
  }
}

export function userLogin(username, password, redirect) {
  return function(dispatch) {
    dispatch({type: 'USER_LOGIN' });
    axios.post(`/api/auth/login?redirect=${redirect}`, { username, password })
      .then(response => {
        if(response.status === 202) {
          dispatch({type: 'USER_LOGIN_SUCCESS', payload:  redirect });
        }         
      })
      .catch(error => {
        dispatch({type: 'USER_LOGIN_ERROR', payload:  error.response.data });
      });
  }
}

export function searchUsers(data, params) {
  return function(dispatch) {
    dispatch({type: 'SEARCH_USERS' });
    axios.post(`/api/user/search`, data)
      .then(response => {
        dispatch({type: 'SEARCH_USERS_SUCCESS', payload: { data: response.data, params } });
      })
      .catch(err => {
        dispatch({type: 'SEARCH_USERS_SUCCESS_ERROR', payload: err });
      });
  }
}

export function showLoginModal() {
  return function(dispatch) {
    dispatch({type: 'SHOW_LOGIN_MODAL' });
  }
}

export function toggleDarkmode() {
  return function(dispatch) {
    dispatch({type: 'TOGGLE_DARKMODE' });
  }
}

export function enableDarkmode() {
  return function(dispatch) {
    dispatch({type: 'ENABLE_DARKMODE' });
  }
}

export function disableDarkmode() {
  return function(dispatch) {
    dispatch({type: 'DISABLE_DARKMODE' });
  }
}
