

import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';

class PlayerGamesGrid extends React.Component {
	constructor(props) {
		super(props);
		const player = props.player;
		player.games = player.games || [];
		player.games = player.games.sort((a, b) => { return moment(b.game_date).toDate() - moment(a.game_date).toDate() })

		const columnDefs = this.buildColDef();

		this.state = {
			darkmode: props.darkmode,
			selectedPlayers: [],
			defaultColDef: {
				sortable: true,
				resizable: true,
				filter: true
			},
			columnDefs,
			games: player.games,
			pages: 0,
			total: 0
		}
	}
	specialTeamsPositions = ['LS', 'H', 'KR', 'PR']
	offensiveLinePositions = ['OL', 'C', 'G', 'LG', 'RG', 'OG']
	offensivePositions = ['RB', 'FB', 'WR', 'TE','K']; //position players
	defensivePositions = ['SS', 'FS', 'S', 'LS', 'DB', 'LB', 'ILB', 'DL','OLB', 'DT', 'LB', 'LDT', 'RDT', 'CB', 'T', 'OT',  'DE', 'U'];						 
	stats = {
		'pass_att': { positions: ['QB'], header: 'PAtt' },
		'pass_cmp': { positions: ['QB'], header: 'Cmp' },
		'pass_yds': { positions: ['QB'], header: 'Pass Yds' },
		'pass_cmp_perc': { positions: ['QB'], header: 'Cmp %' },			
		'pass_yds_per_att': { positions: ['QB'], header: 'YPA' },	
		'pass_adj_yds_per_att': { positions: ['QB'], header: 'AYPA' },
		'pass_td': { positions: ['QB'], header: 'Pass TD' },
		'pass_int': { positions: ['QB'], header: 'Int' },
		'pass_rating': { positions: ['QB'], header: 'Rat' },
		'pass_sacked': { positions: ['QB'], header: 'Sckd' },
		'pass_sacked_yds': { positions: ['QB'], header: 'Sck Yds' },
		'targets': { positions: this.offensivePositions, header: 'Tgt' },
		'rec': { positions: this.offensivePositions, header: 'Rec' },
		'rec_yds': { positions: this.offensivePositions, header: 'Rec Yds' },
		'rec_td': { positions: this.offensivePositions, header: 'Rec TD' },
		'rec_yds_per_rec': { positions: this.offensivePositions, header: 'YPRec' },
		'rec_yds_per_tgt': { positions: this.offensivePositions, header: 'YPT' },
		'catch_pct': { positions: this.offensivePositions, header: 'Cat %' },
		'rush_att': { positions: [...this.offensivePositions, 'QB'], header: 'Rsh Att' },
		'rush_yds': { positions: [...this.offensivePositions, 'QB'], header: 'Rsh Yds' },
		'rush_yds_per_att':  { positions: [...this.offensivePositions, 'QB'], header: 'RYPA' },
		'rush_td':  { positions: [...this.offensivePositions, 'QB'], header: 'Rsh TD' },
		'tackles_solo': { positions: this.defensivePositions, header: 'Tck Solo' },
		'tackles_assist': { positions: this.defensivePositions, header: 'Tck Ast' },
		'tackles_combined': { positions: this.defensivePositions, header: 'Tck Cmb' },
		'tackles_loss': { positions: this.defensivePositions, header: 'Tck Lss' },
		'qb_hits': { positions: this.defensivePositions, header: 'QB Hit' },
		'fumbles_forced': { positions: this.defensivePositions, header: 'FF' },
		'fumbles_rec': { positions: this.defensivePositions, header: 'FRec' },
		'fumbles_rec_yds': { positions: this.defensivePositions, header: 'FRecY' },
		'fumbles_rec_td': { positions: this.defensivePositions, header: 'Fum TD' },
		'def_int':{ positions: this.defensivePositions, header: 'Int' },
		'def_int_yds': { positions: this.defensivePositions, header: 'Int Yds' },
		'def_int_td': { positions: this.defensivePositions, header: 'Int TD' },		
		'pass_defended': { positions: this.defensivePositions, header: 'Pass Def' },
		'kick_ret':  { positions: this.specialTeamsPositions, header: 'KR' },
		'kick_ret_yds':  { positions: this.specialTeamsPositions, header: 'KRYds' },
		'kick_ret_td':  { positions: this.specialTeamsPositions, header: 'KRTD' },
		'punt_ret':  { positions: this.specialTeamsPositions, header: 'PR' },
		'punt_ret_yds':  { positions: this.specialTeamsPositions, header: 'PRYds' },
		'punt_ret_td':  { positions: this.specialTeamsPositions, header: 'PRTD' },
		'xpm':  { positions: ['K'], header: 'XPM' },
		'xpa':  { positions: ['K'], header: 'XPA' },
		'fgm':  { positions: ['K'], header: 'FGM' },
		'fga':  { positions: ['K'], header: 'FGA' },
	}
	
	buildColDef = () => {
		const player = this.props.player;
		let columnDefs = [
			{ 
				headerName: "#", 
				field: "game_number",
				filter: 'agNumberColumnFilter',			
			},
			{
				headerName: "Date",
				field: "game_date",
				filter: 'agDateColumnFilter',
				valueFormatter: (params) => {
					return `${moment(params.value).format('MM-DD-YYYY')}`;
				}
			},
			{ 
				headerName: "Opp. Team", 
				field: "opposing_team",
				filter: "agTextColumnFilter",
			},
			{ 
				headerName: "@", 
				field: "game_location",
				filter: "agTextColumnFilter",				
			},
			{ 
				headerName: "TD", 
				field: "all_td",
				filter: "agNumberColumnFilter"		
			},
			{ 
				headerName: "Fum", 
				field: "fumbles",
				filter: "agNumberColumnFilter",
			}
		];

		Object.keys(this.stats).forEach(key => {
			const statDetails = this.stats[key];
			columnDefs.push({
				headerName: statDetails.header,
				field: key,
				filter: "agNumberColumnFilter",
				hide: !statDetails.positions.includes(player.position)
			});
		})

		return columnDefs;
	}
    
    componentWillReceiveProps(props) {
		const { resized, player } = props;
		if(resized && moment(resized) && moment(resized).add(1, 'second').isAfter(moment()) && this.gridApi){
			this.gridApi.sizeColumnsToFit();
		}
		if(player) {		
			player.games = player.games || [];
			player.games = player.games.sort((a, b) => { return moment(b.game_date).toDate() - moment(a.game_date).toDate() })
		}
	}

	onGridReady = (params) => {
        params.api.sizeColumnsToFit();
		this.gridApi = params.api;
        this.columnApi = params.columnApi;
	}

	render() {
		return (
			<div className={`ag-theme-balham${this.props.darkmode ? '-dark' : ''}`} style={{ height: '100%', width: '100%' }}>
                <AgGridReact		
                    suppressCellSelection={true}
					onGridReady={this.onGridReady}
					defaultColDef={this.state.defaultColDef}
					columnDefs={this.state.columnDefs}
					rowData={this.props.player.games}
				>
				</AgGridReact>				
			</div>
		);
	}
}

export default PlayerGamesGrid;