import axios from 'axios';

export function getDefenses(data, params) {
    return function(dispatch) {
      dispatch({type: 'GET_ALL_DEFENSES' });
      axios.get(`/api/defense/all`)
        .then(response => {
          dispatch({type: 'GET_ALL_DEFENSES_SUCCESS', payload: { data: response.data, params } });
        })
        .catch(err => {
          dispatch({type: 'GET_ALL_DEFENSES_ERROR', payload: err });
        });
    }
}