import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Navbar, Badge } from 'react-bootstrap';
import { getBuildInfo } from '../actions/commonAction';
import { checkAuthStatus } from 'actions/userActions';

function mapStoreStateToProps(store) {
  return {
    buildInfo: store.common.buildInfo,
    authenticated: store.user.authenticated
  }
}

class Footer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      authenticated: false,
      buildInfo: {}
    };
  }

  componentWillMount() {
    this.props.dispatch(checkAuthStatus());
    this.props.dispatch(getBuildInfo());
  }

  componentWillReceiveProps(props) {
    const { buildInfo, authenticated } = props;
    this.setState({
      ...this.state,
      authenticated,
      buildInfo
    });
  }

  render() {
    const { buildInfo, darkmode } = this.state;
    return (
      <Navbar fixed="bottom" bg="primary" variant="dark">      
        <Navbar.Text className="mr-auto">         
          <Badge>&copy; 2022 <a href='https:///jfierstein.net'>Josh Fierstein</a></Badge>
        </Navbar.Text>
        <Navbar.Text className="ml-auto">
            <Badge className="mr-2" variant="warning">App Version: {buildInfo && buildInfo.AppVersion}</Badge>
            <Badge variant="secondary">Last built: {moment(buildInfo && buildInfo.BuildTimestamp).calendar()}</Badge>
        </Navbar.Text>
      </Navbar>
    );
  }
}

export default connect(mapStoreStateToProps)(Footer);