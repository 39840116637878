export default function reducer(state = {
    searchResults: {
      results: [],
      total: 0      
    },
    updatePlayer: null,
    fetching: false
}, action) {
  switch (action.type) {
    case 'PLAYER_UPDATE_EVENT': {
      const { searchResults } = state;
      if(action.data && action.data.player) {
        const resultIndex = searchResults.results.findIndex(r => r._id === action.data.player._id);
        if(resultIndex >= 0) {
          searchResults.results[resultIndex] = action.data.player;
        }
        return {
          ...state,
          searchResults,
          updatePlayer: action.data.player,

        }
      }
      else {
        return {
          ...state
        }
      }
    }
    case 'SEARCH_PLAYERS': {
      return {
        ...state,
        searchingPlayers: true
      }
    }
    case 'SEARCH_PLAYERS_SUCCESS': {
      const { data, params } = action.payload;
      const { results, total, pages } = data;
      return {
        ...state,
        searchingPlayers: false,
        searchResults: {
          results,
          total,
          pages,
          params
        }
      }
    }
    case 'SEARCH_PLAYERS_ERROR': {
      return {
        ...state,
        searchingPlayers: false
      }
    }
    case 'GET_PLAYER_DETAILS': {
      return {
        ...state,
        fetchingPlayerDetails: true
      }
    }
    case 'GET_PLAYER_DETAILS_SUCCESS': {
      return {
        ...state,
        fetchingPlayerDetails: false,
        playerDetails: action.payload
      }
    }
    case 'GET_PLAYER_DETAILS_ERROR': {
      return {
        ...state,
        fetchingPlayerDetails: false
      }
    }
    default: {
      return { ...state }
    }
  }
}