export default function reducer(state = {
  fetching: false,
  results: [],
  error: null
}, action) {
  switch (action.type) {
    case 'GEOCODE_AUTO_COMPLETE_SUCCESS': {    
      return {
        ...state,
        results: action.payload.results
      }
    }
    case 'GEOCODE_DETAILS_SUCCESS': {    
      return {
        ...state,
        placeDetails: action.payload
      }
    }
    default: {
      return { ...state }
    }
  }
}