import React from 'react';
import { connect } from 'react-redux';
import { Card, Row, Col, Alert, ProgressBar } from 'react-bootstrap';
import PlayersGrid from './grid/PlayersGrid';
import PlayerGamesGrid from './grid/PlayerGamesGrid';
import PlayerChart from './grid/PlayerChart';

import LoadingSpinner from 'components/common/LoadingSpinner';
import { getPlayerDetails } from 'actions/playerActions';

const mapStoreStateToProps = (store) => {
    return {
        authenticated: store.user.authenticated,
        playerDetails: store.player.playerDetails,
        searchingPlayers: store.player.searchingPlayers,
        fetchingPlayerDetails: store.player.fetchingPlayerDetails,
        fetchProgress: store.events.fetchProgress,
        darkmode: store.user.darkmode
    };
}

class PlayersHome extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedRows: [],
            selectedPlayer: null,
            playerDetails: {},
            searchingPlayers: false
        };
    }

    componentWillReceiveProps(props) {
        const { fetchProgress, playerDetails, searchingPlayers, fetchingPlayerDetails, authenticated, darkmode } = props;
        this.setState({ ...this.state, darkmode, authenticated, fetchProgress, searchingPlayers, fetchingPlayerDetails, playerDetails: playerDetails ? playerDetails : {} });
    }

    componentDidMount() {
        window.onresize = (e) => {
            this.setState({ ...this.state, resized: new Date() });
        }
    }

    onRowSelected = (selectedRows) => {
        this.setState({ ...this.state, selectedRows }, () => {
            if (this.state && this.state.selectedRows && this.state.selectedRows.length) {
                this.props.dispatch(getPlayerDetails(this.state.selectedRows[0]._id));
            }
        });
    }

    closePlayerChart = () => {
        //this.playersGrid.
        this.setState({ ...this.state, selectedRows: [], resized: new Date() }, () => {});
    }

    render() {
        const { fetchProgress, selectedRows, playerDetails, fetchingPlayerDetails, darkmode } = this.state;
        return (
            <div className="mr-2 ml-2 mt-5" style={{ overflowX: 'hidden' }}>
                {fetchProgress ?
                    <Alert variant="info" className="fade-in mt-2" style={{ marginBottom: '0px' }}>
                        <i className="mdi mdi-progress-upload mr-1" /><span>Data refresh job in progress...</span>                    
                        <ProgressBar variant="warning" animated now={fetchProgress.overall} label={<span className="text-primary">{fetchProgress.overall}%</span>} />
                        Current Player: {fetchProgress.player.name} ({fetchProgress.player.progress}%)
                    </Alert>
                    : null}
                <Row>
                    <Col lg="12" xl={selectedRows.length === 1 ? 6 : 12}>
                        <Card className="app-card shadow mt-2">
                            <Card.Header style={{ padding: '0.25rem' }}>
                                <i className="mdi mdi-football-helmet mr-1" /> NFL Player List
                            </Card.Header>
                            <div className="fade-in" style={{ height: '50vh' }}>
                                <PlayersGrid ref={(r) => this.playersGrid = r} onRowSelected={this.onRowSelected} resized={this.state.resized} key='playersGrid' darkmode={darkmode} />
                            </div>
                        </Card>
                    </Col>
                    <Col lg="12" xl="6">
                        {selectedRows.length === 1 ?
                            <Card className="app-card shadow mt-2">
                                <Card.Header style={{ padding: '0.25rem', marginBottom: '1.25em' }}>
                                    <i className="mdi mdi-chart-areaspline ml-1 mr-1" /> Stats: {playerDetails.name} <i className="mdi mdi-close pull-right hover-zoom" onClick={this.closePlayerChart} />
                                </Card.Header>
                                {!fetchingPlayerDetails ? <PlayerChart player={playerDetails} /> : <LoadingSpinner />}
                            </Card>
                            : null}
                    </Col>
                </Row>
                {selectedRows.length === 1 ?
                    <div>
                        {!fetchingPlayerDetails && playerDetails ? (
                            <Card className="app-card fade-in shadow">
                                <Card.Header style={{ padding: '0.25rem' }} >
                                    <i className="mdi mdi-folder-account-outline ml-1 mr-1" /> Game Logs: {playerDetails.name}
                                </Card.Header>
                                <div style={{ height: '25vh', overflowY: 'auto', width: '100%' }}>
                                    <PlayerGamesGrid ref={(r) => this.playerGamesGrid = r} player={playerDetails} resized={this.state.resized} key='playerGamesGrid' darkmode={darkmode} />
                                </div>
                            </Card>) : <LoadingSpinner />}
                    </div>
                    : null}

            </div>
        )

    }
}

export default connect(mapStoreStateToProps)(PlayersHome)