import React from 'react';
import { connect } from 'react-redux';
import history from 'routes/history';
import { Form, Row, Col, InputGroup } from 'react-bootstrap';

import { getAuthStatus, checkAuthStatus } from 'actions/userActions';

class ScoringSettings extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      authenticating: props.authenticating,
      authenticated: props.authenticated,
      restrictedPaths: ['/listings/apply'],
      scoringFields: {
        pass_att: { label: 'Pass Attempts', pts_per: 0 },
        pass_cmp: { label: 'Comp. Passes', pts_per: 0 },
        pass_yds: { label: 'Passing YDs', pts_per: 0 },
        pass_td: { label: 'Passings TDs', pts_per: 0 },
        pass_int: { label: 'Passings INTs', pts_per: 0 },
        pass_sacked: { label: 'Passings INTs', pts_per: 0 },
        //RECIEVING
        targets: { label: 'Target', pts_per: 0 },
        rec: { label: 'Receptions', pts_per: 0 },
        rec_yds: { label: 'Receiving YDs', pts_per: 0 },
        rec_td: { label: 'Receiving TDs', pts_per: 0 },
        //RUSHING
        rush_att: { label: 'Rushing Attempts', pts_per: 0 },
        rush_yds: { label: 'Rushing YDs', pts_per: 0 },
        rush_td: { label: 'Rushing TDs', pts_per: 0 },
        two_pt_md: { label: '2Pt Conv.', pts_per: 0 },
        //FUMBLES
        fumbles: { label: 'Fumbles', pts_per: 0 },
        //DEFENSIVE
        sacks: { label: 'Sacks', pts_per: 0 },
        tackles_solo: { label: 'Tackles', pts_per: 0 },
        qb_hits: { label: 'QB Hits', pts_per: 0 },
        fumbles_forced: { label: 'Forced Fumbles', pts_per: 0 },
        fumbles_rec_td: { label: 'Fumble TD', pts_per: 0 },
        def_int: { label: 'Defensive INT', pts_per: 0 },
        def_int_yds: { label: 'Defensive INT YDs', pts_per: 0 },
        def_int_td: { label: 'Defensive INT TDs', pts_per: 0 },
        //SPECIAL TEAMS
        kick_ret_yds: { label: 'Kickoff Return YDs', pts_per: 0 },
        kick_ret_td: { label: 'Kickoff Return TDs', pts_per: 0 },
        punt_ret_yds: { label: 'Punt Return YDs', pts_per: 0 },
        punt_ret_td: { label: 'Punt Return TDs', pts_per: 0 },
        //KICKING
        xpm: { label: 'Extra Points Made', pts_per: 0 },
        xpa: { label: 'Extra Points Attempted', pts_per: 0 },
        fgm: { label: 'FG Made', pts_per: 0 },
        fga: { label: 'FG Attempted', pts_per: 0 }
      },
      selectedField: 'pass_att',
      enteredPointValue: null
    };
  }

  buildSettingsOptions = () => {
    const { scoringFields } = this.state;
    const options = [];
    Object.keys(scoringFields).forEach(field => {
      options.push(<option value={field}>{scoringFields[field].label}</option>);
    });
    return options;
  }

  handleSettingOptionChange = (e) => {
    this.setState({ ...this.state, selectedField: e.target.value });
  }

  onValueChange = (field, e) => {
    const { scoringFields } = this.state;
    scoringFields[field].pts_per = e.target.valueAsNumber;
    this.setState({...this.state, scoringFields });
  }

  renderSettings = () => {
    const { scoringFields } = this.state;
    let settings = [];
    Object.keys(scoringFields).forEach(field => {
      if(scoringFields[field].pts_per !== 0) {
      settings.push(
        <div>
          {scoringFields[field].pts_per} pts per {scoringFields[field].label.toLowerCase()}
        </div>);
      }
    });
    return settings;
  }

  render() {
    const { authenticated, user } = this.state;
    let name = '';
    if (user) name = `${user.email}`
    const enteredValue = this.state.scoringFields[this.state.selectedField] ? this.state.scoringFields[this.state.selectedField].pts_per : 0;
    return (
        <div>
            <Row>
              <Col xs="6">
                <Form.Group>
                  <Form.Label>Field</Form.Label>
                  <Form.Control as="select" placeholder="Select..." onChange={this.handleSettingOptionChange}>
                    {this.buildSettingsOptions()}
                  </Form.Control>
                  <Form.Control.Feedback />
                </Form.Group>
              </Col>
              <Col xs="6">
                <Form.Group>
                <Form.Label>Fantasy Scoring</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text>Points Per</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      onChange={e => this.onValueChange(this.state.selectedField, e)}
                      type="number"
                      value={enteredValue}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <Row>
               <Col xs="12">
                 {this.renderSettings()}
               </Col>
            </Row>
       </div>
    );
  }
}

export default (ScoringSettings);