import React from 'react';
import { connect } from 'react-redux';
import { Card, Row, Col } from 'react-bootstrap';
import UsersGrid from './grid/UsersGrid';

const mapStoreStateToProps = (store) => {
    return {
        authenticated: store.user.authenticated,
        searchingUsers: store.player.searchingUsers
    };
}

class UsersHome extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedRow: [],
            selectedUser: null,
            searchingUsers: false
        };
    }

    componentWillReceiveProps(props) {
        const { searchingUsers, authenticated } = props;
        this.setState({ ...this.state, authenticated, searchingUsers });
    }

    componentDidMount() {
        window.onresize = (e) => {
            this.setState({ ...this.state, resized: new Date() });
        }
    }

    onRowSelected = (selectedRow) => {
        this.setState({ ...this.state, selectedRow });
    }

    render() {
        const { selectedRow } = this.state;
        const user = selectedRow[0];
        return (
            <div className="mr-2 ml-2 mt-5" style={{ overflowX: 'hidden' }}>                
                <Row>
                    <Col xs="6" md="6">
                        <Card className="app-card shadow mt-2">
                            <Card.Header style={{ padding: '0.25rem' }}>
                                <i className="mdi mdi-user mr-1" /> User List
                            </Card.Header>
                            <div className="fade-in" style={{ height: '50vh' }}>
                                <UsersGrid ref={(r) => this.playersGrid = r} onRowSelected={this.onRowSelected} resized={this.state.resized} />
                            </div>
                        </Card>
                    </Col>
                    <Col xs="6" md="6">
                        { user ? 
                        <Card className="app-card shadow mt-2">
                            <Card.Header style={{ padding: '0.25rem' }}>
                                <i className="mdi mdi-user mr-1" /> User Details
                            </Card.Header>
                            <Card.Body>
                                <p>
                                Name: {user.name}<br />
                                Email: {user.email}<br />
                                Active: {user.isActive ? 'Yes' : 'No'}<br />
                                Admin: {user.isAdmin ? 'Yes' : 'No'}
                                </p>
                            </Card.Body>
                        </Card> : null }
                    </Col>
                </Row>
            </div>
        )

    }
}

export default connect(mapStoreStateToProps)(UsersHome)