import React from 'react';
import { connect } from 'react-redux';
import history from 'routes/history';
import { eventStream } from 'actions/eventActions';
import { Navbar, NavDropdown, Nav } from 'react-bootstrap';
import './Header.css';
import { getAuthStatus, checkAuthStatus, toggleDarkmode, enableDarkmode, disableDarkmode } from 'actions/userActions';
const LightTheme = React.lazy(() => import('../css/themes/light'));
const DarkTheme = React.lazy(() => import('../css/themes/dark'));

function mapStoreStateToProps(store) {
  return {
    authenticated: store.user.authenticated,
    authenticating: store.user.authenticating,
    attempted: store.user.attempted,
    check_timestamp: store.user.check_timestamp,
    error: store.user.error,
    user: store.user.user,
    darkmode: store.user.darkmode
  }
}

class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      authenticating: props.authenticating,
      authenticated: props.authenticated,
      restrictedPaths: ['/listings/apply']
    };
  }

  componentWillMount() {
    this.props.dispatch(checkAuthStatus());
    this.props.dispatch(eventStream());
  }

  componentWillReceiveProps(props) {
    const { darkmode, authenticated, authenticating, attempted, user, error } = props;
    if (!authenticated && !authenticating && !attempted) {
      this.props.dispatch(getAuthStatus());
      return;
    }
    if (error && this.state.restrictedPaths.includes(window.location.pathname)) {
        window.location.href = '/login';
    }
    this.setState({
      ...this.state,
      user,
      authenticated,
      authenticating,
      darkmode
    });
  }

  logout = () => {
    window.location.href = '/api/auth/logout';
  }

  _enableDarkmode() {
    this.props.dispatch(enableDarkmode());
  }

  _disableDarkmode() {
    this.props.dispatch(disableDarkmode());
  }

  _toggleDarkmode() {
    this.props.dispatch(toggleDarkmode());
  }

  render() {
    const { authenticated, user, darkmode } = this.state;
    let name = '';
    if (user) name = `${user.email}`
    return (
      <Navbar fixed="top" style={{ padding: '0.2rem 0.5rem' }} className="shadow" bg="primary" variant="dark" expand="sm">
      <React.Suspense fallback={<></>}>
        {darkmode ? <DarkTheme /> : <LightTheme />}
      </React.Suspense>
        <Navbar.Brand className="pointer" href="/">
          <i className="ml-1 mdi mdi-football mr-2"/>FF Source
        </Navbar.Brand>        
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">          
          <Nav className="mr-auto">
            <NavDropdown title={<span className="nav-drop-title">Player Data</span>} id="crm-dropdown">
              <NavDropdown.Item onClick={e => history.push('/players')}><i className="mdi mdi-football-helmet mr-2" />All Players</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={<span className="nav-drop-title">Defenses</span>} id="crm-dropdown">
              <NavDropdown.Item onClick={e => history.push('/defenses')}><i className="mdi mdi-football mr-2" />Overview</NavDropdown.Item>
            </NavDropdown>            
          </Nav>
          {/* <Navbar.Text>
           <i onClick={ e => { !darkmode ? this._enableDarkmode() : this._disableDarkmode() }} className="ml-2 fa-lg mdi mdi-theme-light-dark mr-2" title="Dark/Light Mode" />
          </Navbar.Text>*/}
          { authenticated ?
            <NavDropdown alignRight id="account-dropdown"
              title={
              <span>Welcome, {name} 
                <i className="fa fa-user-circle ml-2" />
              </span>}>
              <NavDropdown.Item className="center-align" onClick={ e => history.push('/my-leagues')}>My Leagues<i className="mdi mdi-trophy-outline ml-2" /></NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item className="center-align" href="/auth/logout">Sign Out <i className="fa fa-sign-out ml-2" /></NavDropdown.Item>
            </NavDropdown> :
            <Navbar.Text onClick={ e => window.location.href = '/auth/google' } className="mr-1 pointer">
              Sign In<i className="fa fa-angle-right ml-2" />
            </Navbar.Text> }
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default connect(mapStoreStateToProps)(Header);