import React from 'react';
import moment from 'moment';
import {
    ComposedChart, Line, ReferenceLine, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Scatter, Bar, Area
} from 'recharts';
import LoadingSpinner from 'components/common/LoadingSpinner';

const CustomizedDot = (props) => {
    const {
        cx, cy, value,
    } = props;
    const icon = (y) => { return (
        <svg x={cx-4} y={y} fill="mediumseagreen" width={8} height={8} viewBox="0 0 24 24">
            <path d="M7.5,7.5C9.17,5.87 11.29,4.69 13.37,4.18C15.46,3.67 17.5,3.83 18.6,4C19.71,4.15 19.87,4.31 20.03,5.41C20.18,6.5 20.33,8.55 19.82,10.63C19.31,12.71 18.13,14.83 16.5,16.5C14.83,18.13 12.71,19.31 10.63,19.82C8.55,20.33 6.5,20.18 5.41,20.03C4.31,19.87 4.15,19.71 4,18.6C3.83,17.5 3.67,15.46 4.18,13.37C4.69,11.29 5.87,9.17 7.5,7.5M7.3,15.79L8.21,16.7L9.42,15.5L10.63,16.7L11.54,15.79L10.34,14.58L12,12.91L13.21,14.12L14.12,13.21L12.91,12L14.58,10.34L15.79,11.54L16.7,10.63L15.5,9.42L16.7,8.21L15.79,7.3L14.58,8.5L13.37,7.3L12.46,8.21L13.66,9.42L12,11.09L10.79,9.88L9.88,10.79L11.09,12L9.42,13.66L8.21,12.46L7.3,13.37L8.5,14.58L7.3,15.79Z" />
        </svg>
    ); };
    const balls = [];
    if (value > 0) {
        for (let i = 0; i <= value; i++) {
            balls.push(icon(0 + (i * 10)));
        }
        return balls;
    }
    else return (null);
};

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload; // weird structure from Recharts...each payload object within the array elements is the same.
      const fontSize = '12px';
      return (
        <div className="custom-tooltip">
          <p className="label">{`vs ${data.opposing_team} - ${label}`}</p>
          <div style={{ display: "inline-block", padding: 10 }}>
                { data.pass_att > 0 ? 
                    <div style={{ color: 'dodgerblue', fontSize }}>
                    { data.pass_cmp || 0  } cmp/{ data.pass_att } att
                    </div> : null } 
                { data.pass_yds > 0 ?  
                    <div style={{ color: 'orange', fontSize }}>
                    { data.pass_yds } pass yds
                    </div> : null }             
            </div>
            <div style={{ display: "inline-block", padding: 10 }}>
                { data.rush_att > 0 ? 
                    <div style={{ color: 'dodgerblue', fontSize }}>
                    { data.rush_att } carries
                    </div> : null } 
                { data.rush_yds > 0 ?  
                    <div style={{ color: 'orange', fontSize }}>
                    { data.rush_yds } rush yds
                    </div> : null }
                { data.targets > 0 ? 
                    <div style={{ color: 'dodgerblue', fontSize }}>
                    { data.targets } targets
                    </div> : null }
                { data.rec_yds > 0 ?  
                    <div style={{ color: 'orange', fontSize }}>
                    { data.rec_yds } rec yds
                    </div> : null }                                       
            </div>
            <div style={{ display: "inline-block", padding: 10 }}>
                { data.pass_td > 0 ?  
                    <div style={{ color: 'mediumseagreen', fontSize }}>
                    { data.pass_td } pass TD
                    </div> : null }
                { data.rush_td > 0 ? 
                    <div style={{ color: 'mediumpurple', fontSize }}>
                    { data.rush_td } rush TD
                    </div> : null } 
                { data.rec_td > 0 ? 
                    <div style={{ color: 'mediumseagreen', fontSize }}>
                    { data.rec_td } rec TD
                    </div> : null }                     
                { data.pass_int > 0 ? 
                    <div style={{ color: 'red', fontSize }}>
                    { data.pass_int } pass INT
                    </div> : null }                     
            </div>
        </div>
      );
    }
  
    return null;
  };

class PlayerChart extends React.Component {

    constructor(props) {
        super(props);
        let data = [];
        if(props.player.games) {
            data = this.getData(props.player.games);
        }
        this.state = {
            player: props.player,
            data
        }
    }

    getData(games) {
        games = games || [];
        const data = [];
        games = games.filter(g => { return moment(g.game_date).isAfter(moment().subtract(3, 'years')) })
                     .sort((a, b) => { return moment(a.game_date).toDate() - moment(b.game_date).toDate() })
                     .forEach(game => {
                        const requiredFields = ['rush_yds', 'pass_yds', 'rec_yds', 'rush_td', 'rec_td', 'pass_td', 'targets', 'rush_att'];
                        requiredFields.forEach(field => { 
                            game[field] = game[field] || 0; 
                        });
                        game.rec_rush_td = game.rec_td + game.rush_td;
                        game.pass_rush_td = game.pass_td + game.rush_td;                        
                        data.push({ game: `${moment(game.game_date).format('MM/DD/YYYY')}`, ...game });              
                    });
        return data;
    }

    componentWillReceiveProps(props) {
        const { player } = props;
        player.games = player.games || [];
        player.games = player.games.filter(g => { return moment(g.game_date).isAfter(moment().subtract(3, 'years')) })
                                   .sort((a, b) => { return moment(a.game_date).toDate() - moment(b.game_date).toDate() })
        const data = this.getData(player.games);
        this.setState({ ...this.state, player, data, ready: true });
    }

    render() {
        const { data, player } = this.state;
        return (
            <ResponsiveContainer width="99%" aspect={3} style={{marginBottom: '1.25em'}}>
                <ComposedChart width={500} data={data} margin={{ top: 10, right: 10, bottom: 10, left: 10 }}>                    
                    <XAxis dataKey="game" />
                    <YAxis hide domain={[0, 10]}/>

                    { ['WR', 'TE', 'RB'].includes(player.position) ? 
                    <YAxis yAxisId="rec_rush_yds" orientation="left" domain={[0, 250]} stroke="#ff7300" /> : null }

                    { ['QB'].includes(player.position) ? 
                    <YAxis yAxisId="pass_yds" orientation="left" domain={[0, 500]} stroke="#ff7300" /> : null }

                    { ['WR', 'TE', 'QB', 'RB'].includes(player.position) ? 
                    <YAxis yAxisId="tds" hide orientation="right" domain={[0, 20]} stroke="slateblue" /> : null }

                    { ['WR', 'TE', 'RB'].includes(player.position) ? 
                    <YAxis yAxisId="att" orientation="right" domain={[0, 50]} stroke="dodgerblue" /> : null }

                    { ['WR', 'TE', 'RB'].includes(player.position) ? 
                    <ReferenceLine y={100} yAxisId="rec_rush_yds" stroke="mediumseagreen" strokeDasharray="3 3" label={{ value: '100 yds', fill: 'lightsteelblue' }} /> : null }
                    { ['QB'].includes(player.position) ? 
                    <ReferenceLine y={300} yAxisId="pass_yds" stroke="mediumseagreen" strokeDasharray="3 3" label={{ value: '300 yds', fill: 'lightsteelblue' }}/> : null }
                    
                    { ['WR', 'TE'].includes(player.position) ? <Bar stackId="REC_stats" yAxisId="tds" dataKey="rec_td" fill="mediumseagreen" /> : null }
                    { ['WR', 'TE'].includes(player.position) ? <Bar stackId="REC_stats" yAxisId="tds" dataKey="rush_td" fill="mediumpurple" /> : null }

                    { ['RB'].includes(player.position) ? <Bar stackId="RB_stats" yAxisId="tds" dataKey="rec_td" fill="mediumseagreen" /> : null }
                    { ['RB'].includes(player.position) ? <Bar stackId="RB_stats" yAxisId="tds" dataKey="rush_td" fill="mediumpurple" /> : null }


                    { ['QB'].includes(player.position) ? <Bar stackId="QB_stats" yAxisId="tds" dataKey="pass_td" fill="mediumseagreen" /> : null }
                    { ['QB'].includes(player.position) ? <Bar stackId="QB_stats" yAxisId="tds" dataKey="rush_td" fill="mediumpurple" /> : null }
                    { ['QB'].includes(player.position) ? <Bar stackId="QB_stats" yAxisId="tds" dataKey="pass_int" fill="red" /> : null }
                    
                    { ['QB'].includes(player.position) ? <Line yAxisId="pass_yds" type="monotone" dataKey="pass_yds" stroke="orange" dot={false} /> : null }

                    { ['RB'].includes(player.position) ? <Line yAxisId="rec_rush_yds" type="monotone" dataKey="rush_yds" stroke="orange" dot={false} /> : null }
                    { ['RB'].includes(player.position) ? <Line yAxisId="att" type="monotone" dataKey="rush_att" stroke="dodgerblue" dot={false} /> : null }
                    { ['WR', 'TE'].includes(player.position) ? <Line yAxisId="rec_rush_yds" type="monotone" dataKey="rec_yds" stroke="orange" dot={false} /> : null }                    
                    { ['WR', 'TE'].includes(player.position) ? <Line yAxisId="att" type="monotone" dataKey="targets" stroke="dodgerblue" dot={false} /> : null }

                    { !['WR', 'TE', 'QB', 'RB'].includes(player.position) ? 
                    <Line type="monotone" dataKey="sck" stroke="#ff7300" dot={false} /> : null}
                    { !['WR', 'TE', 'QB', 'RB'].includes(player.position) ? 
                    <Line type="monotone" dataKey="tkl" stroke="#508991" dot={false} /> : null}
                    { !['WR', 'TE', 'QB', 'RB'].includes(player.position) ? 
                    <Line type="monotone" dataKey="int" stroke="#413ea0" dot={false} /> : null}
                    { player.position === 'K' ? 
                    <Line type="monotone" dataKey="fgm" stroke="#413ea0" dot={false} /> : null}
                    { player.position === 'K' ? 
                    <Line type="monotone" dataKey="xpm" stroke="#ff7300" dot={false} /> : null}
                    <Tooltip content={<CustomTooltip />} wrapperStyle={{ backgroundColor: "#222", border: "1px solid", paddingLeft: "10px", paddingRight: "10px" }} />
                </ComposedChart>
            </ResponsiveContainer>);
    }
}

export default PlayerChart


