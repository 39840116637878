import axios from 'axios';

export function searchPlayers(data, params) {
    return function(dispatch) {
      dispatch({type: 'SEARCH_PLAYERS' });
      axios.post(`/api/players/search`, data)
        .then(response => {
          dispatch({type: 'SEARCH_PLAYERS_SUCCESS', payload: { data: response.data, params } });
        })
        .catch(err => {
          dispatch({type: 'SEARCH_PLAYERS_SUCCESS_ERROR', payload: err });
        });
    }
}

export function getPlayerDetails(id) {
  return function(dispatch) {
    dispatch({type: 'GET_PLAYER_DETAILS' });
    axios.get(`/api/players/details/${id}`)
      .then(response => {
        dispatch({type: 'GET_PLAYER_DETAILS_SUCCESS', payload: response.data });
      })
      .catch(err => {
        dispatch({type: 'GET_PLAYER_DETAILS_ERROR', payload: err });
      });
  }
}

export function getLastUpdated(id) {
  return function(dispatch) {
    dispatch({type: 'GET_PLAYERS_LAST_UPDATED' });
    axios.get(`/api/players/lastupdated`)
      .then(response => {
        dispatch({type: 'GET_PLAYERS_LAST_UPDATED_SUCCESS', payload: response.data });
      })
      .catch(err => {
        dispatch({type: 'GET_PLAYERS_LAST_UPDATED_ERROR', payload: err });
      });
  }
}
