import React from 'react';
import { connect } from 'react-redux';
import history from 'routes/history';
import { Button, Modal, Row, Col, Form, InputGroup } from 'react-bootstrap';
import ScoringSettings from '../scoring/ScoringSettings';

class NewLeagueModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            league: {
                qb: 0,
                rb: 0,
                wr: 0,
                k: 0,
                dst: 0,
                flex: [],
                idp: []
            }
        };
    }

    handleClose = () => {
        this.props.close()
    }

    render() {
        return (
            <Modal show={this.props.show} onHide={this.handleClose} >
                <Modal.Header closeButton>
                    New League
            </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs="6">
                            <Form.Group>
                                <Form.Label>Teams</Form.Label>
                                <Form.Control as="select" placeholder="Select..." onChange={this.handleSettingOptionChange}>
                                    <option value={16}>16</option>
                                    <option value={14}>14</option>
                                    <option value={12}>12</option>
                                    <option value={10}>10</option>
                                    <option value={8}>8</option>
                                </Form.Control>
                                <Form.Control.Feedback />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="4">
                             <Form.Group>
                                <InputGroup className="mb-3">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>QB</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        onChange={e => this.onQBValueChange(e)}
                                        type="number"
                                        value={this.state.league.qb}
                                    />
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col xs="4">
                             <Form.Group>
                                <InputGroup className="mb-3">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>RB</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        onChange={e => this.onQBValueChange(e)}
                                        type="number"
                                        value={this.state.league.qb}
                                    />
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col xs="4">
                             <Form.Group>
                                <InputGroup className="mb-3">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>WB</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        onChange={e => this.onQBValueChange(e)}
                                        type="number"
                                        value={this.state.league.qb}
                                    />
                                </InputGroup>
                            </Form.Group>
                        </Col>
                    </Row>
                    <ScoringSettings />
                </Modal.Body>
                <Modal.Footer>
                    <Button>Add League</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default (NewLeagueModal);