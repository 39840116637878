import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Container, Card, Row, Col, Badge, ListGroup, ListGroupItem } from 'react-bootstrap';
// import PlayersGrid from './grid/PlayersGrid';
import LoadingSpinner from 'components/common/LoadingSpinner';
import { getDefenses } from 'actions/defenseActions';

const mapStoreStateToProps = (store) => {
    return {
        defenses: store.defense.defenses,
        fetching: store.defense.fetching
    };
}

class DefensesHome extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            defenses: []
        };
    }

    componentWillReceiveProps(props) {
        const { defenses, fetching } = props;
        this.setState({ ...this.state, defenses, fetching });
    }

    componentDidMount() {
        window.onresize = (e) => {
            this.setState({ ...this.state, resized: new Date() });
        }
        this.props.dispatch(getDefenses());
    }

    closePlayerChart = () => {
        //this.playersGrid.
        this.setState({ ...this.state, selectedRows: [], resized: new Date() }, () => { });
    }

    renderDefensiveLine = ({defense}) => {
        const dlPositions = ['NT', 'LDT', 'RDT', 'RDE', 'LDE', 'LOLB', 'MLB', 'ROLD', 'LILB', 'RILB'];
        const playerList = [];
        const filteredDef = _.uniqBy(defense, 'name');
        const stats = {
            totalSoloTackles: 0,
            totalTacklesCombined: 0,
            totalSacks: 0,
            totalFumblesFforced: 0,
            totalQbHits: 0,
            totalDefInt: 0
        };
        stats.totalSoloTackles = 0;
        filteredDef.filter(p => p.depthOrder === 1 && dlPositions.includes(p.position)).forEach(dp => {
            const player = dp.id;
            const solo_tackles = player.games.reduce((accumulator, game) => accumulator + game.tackles_solo, 0);
            const tackles_combined = player.games.reduce((accumulator, game) => accumulator + game.tackles_combined, 0);
            const sacks = player.games.reduce((accumulator, game) => accumulator + game.sacks, 0);
            const fumbles_forced = player.games.reduce((accumulator, game) => accumulator + game.fumbles_forced, 0);
            const qb_hits = player.games.reduce((accumulator, game) => accumulator + game.qb_hits, 0);
            const def_int = player.games.reduce((accumulator, game) => accumulator + game.def_int, 0);
            stats.totalSoloTackles += solo_tackles;
            stats.totalTacklesCombined += tackles_combined;
            stats.totalSacks += sacks;
            stats.totalFumblesFforced += fumbles_forced;
            stats.totalQbHits += qb_hits;
            stats.totalDefInt += def_int;
            const playerRow = (
                <ListGroupItem action variant={player.injured ? 'danger' : null}>{player.name} - {player.position} {player.injured ? <Badge variant='warning' className="ml-2">Injured</Badge> : null}</ListGroupItem>
            );
            playerList.push(playerRow);
        });
        stats.totalSoloTackles = Math.round(stats.totalSoloTackles);
        stats.totalQbHits = Math.round(stats.totalQbHits);
        return { playerList, stats };
    }

    renderDefensiveBacks = ({defense}) => {
        const backPositions = ['FS', 'SS', 'DB', 'CB', 'LCB', 'RCB'];
        const playerList = [];
        const filteredDef = _.uniqBy(defense, 'name');
        filteredDef.filter(p => p.depthOrder === 1 && backPositions.includes(p.position)).forEach(dp => {
            const player = dp.id;
            const playerRow = (
                <ListGroupItem action variant={player.injured ? 'danger' : null}>{player.name} - {player.position} {player.injured ? <Badge variant='warning' className="ml-2">Injured</Badge> : null}</ListGroupItem>
            );
            playerList.push(playerRow);
        });
        return playerList;
    }

    renderDefenseTeams = (defenses) => {
        const defenseList = [];
        for (let defense of defenses) {
            const { playerList, stats } = this.renderDefensiveLine(defense)
            const def = { 
            row:(<Row>
                    <Col lg="12">
                        <Card className="app-card shadow mt-2">
                            <Card.Header style={{ padding: '0.25rem' }}>
                                {defense.city} {defense.name}
                            </Card.Header>
                            <Card.Body>
                            <Row>
                                <Col lg="12">
                                    <Card className="app-card shadow mt-2">
                                        <Card.Header style={{ padding: '0.25rem' }}>
                                            <i className="mdi mdi-chart-areaspline ml-1 mr-1" /> Defensive Stats
                                        </Card.Header>
                                        <Card.Body>
                                            <ListGroup>
                                                <ListGroupItem>Solo Tackles: { stats.totalSoloTackles } </ListGroupItem>
                                                <ListGroupItem>Total QB Hits: { stats.totalQbHits } </ListGroupItem>                                               
                                            </ListGroup>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="6">
                                    <Card className="app-card shadow mt-2">
                                        <Card.Header style={{ padding: '0.25rem' }}>
                                            Defensive Line
                                        </Card.Header>
                                        <Card.Body>
                                            <ListGroup>
                                                {playerList}
                                            </ListGroup>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg="6">
                                    <Card className="app-card shadow mt-2">
                                        <Card.Header style={{ padding: '0.25rem' }}>
                                            Defensive Backs
                                        </Card.Header>
                                        <Card.Body>
                                            <ListGroup>
                                                {this.renderDefensiveBacks(defense)}
                                            </ListGroup>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>                                
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            ),
            stats
            };
            defenseList.push(def);
        }
        return defenseList.sort((a, b) => b.stats.totalSoloTackles - a.stats.totalSoloTackles ).map(d => d.row);
    }

    render() {
        const { defenses, fetching } = this.state;
        return (
            <Container className="mb-5">
              <div className="mr-2 ml-2 mt-5" style={{ overflowX: 'hidden', marginBottom: '100px'}}>
                { fetching ? <LoadingSpinner/> : this.renderDefenseTeams(defenses)}
              </div>
            </Container>
        )

    }
}

export default connect(mapStoreStateToProps)(DefensesHome)