export default function reducer(state = {
  darkmode: true
}, action) {
  switch (action.type) {
    case 'CHECK_AUTH_STATUS': {
      return {
        ...state,
        check_timestamp: new Date(Date.now())
      }
    }
    case 'SET_AUTH_STATUS': {
      return {
        ...state,
        user: null,
        loginSuccess: false,
        authenticating: true,
        authenticated: false,        
        attempted: false,
        error: null
      }
    }
    case 'SET_AUTH_STATUS_OK': {
      return {
        ...state,
        user: action.payload,
        loginSuccess: true,
        authenticating: false,
        authenticated: true,
        attempted: true
      }
    }
    case 'SET_AUTH_STATUS_ERR': {
      return {
        ...state,
        error: action.payload,
        authenticating: false,
        authenticated: false,
        attempted: true
      }
    }
    case 'TOGGLE_DARKMODE': {
      return {
        ...state,
        darkmode: !state.darkmode
      }
    }
    case 'ENABLE_DARKMODE': {
      return {
        ...state,
        darkmode: true
      }
    }
    case 'DISABLE_DARKMODE': {
      return {
        ...state,
        darkmode: false
      }
    }
    case 'SUBMIT_USER_REGISTRATION_OK': {
      return {
        submitUserRegistrationSuccess: true,
        userRegistration: action.payload
      }
    }
    case 'SUBMIT_USER_REGISTRATION_ERR': {
      return {
        submitUserRegistrationError: action.payload
      }
    }
    case 'REGISTRATION_CHECK_SUCCESS': {
      return {
        userRegistration: action.payload,
      }
    }
    case 'RECAPTCHA_VERIFY_RESPONSE_SUCCESS': {
      return {
        captchaVerifySuccess: action.payload.success,
      }
    }
    case 'REGISTRATION_VERIFY_SUCCESS': {
      return {
        registrationVerification: action.payload
      }
    }
    case 'USER_LOGIN': {
      return {
        loginSuccess: null,
        loginError: null
      }
    }
    case 'USER_LOGIN_SUCCESS': {
      return {
        loginSuccess: true,
        loginRedirect: action.payload
      }
    }
    case 'USER_LOGIN_ERROR': {
      return {
        loginError: action.payload
      }
    }
    case 'SHOW_LOGIN_MODAL': {
      return {
        showLoginModal: true
      }
    }
    case 'HIDE_LOGIN_MODAL': {
      return {
        showLoginModal: false
      }
    }
    case 'SEARCH_USERS_SUCCESS': {
      const { data, params } = action.payload;
      const { results, total, pages } = data;
      return {
        ...state,
        searchingUsers: false,
        searchResults: {
          results,
          total,
          pages,
          params
        }
      }
    }
    default: {
      return { ...state }
    }
  }
}