

import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import moment from 'moment';
import { utils } from 'common';
import { AgGridReact } from 'ag-grid-react';
import { searchUsers } from '../../../actions/userActions';

const mapStoreStateToProps = (store) => {
	return {
		searchResults: store.user.searchResults,
		updateUser: store.user.updateUser
	};
}

class UsersGrid extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedUsers: [],
			selectedNodes: [],
			defaultColDef: {
				sortable: true,
				filter: true,
				resizable: true,
				enableCellChangeFlash: true
			},
			columnDefs: [
				{ 
					headerName: "User", 
					field: "name",
					filter: 'agTextColumnFilter'
				},
				{ 
					headerName: "E-mail", 
					field: "email",
					filter: "agTextColumnFilter"
				},
			],
			users: [],
			pages: 0,
			total: 0,
			fetching: true
		}
	}

	componentWillReceiveProps(props) {
		const { resized, searchResults, updateUser } = props;
		if(resized && moment(resized) && moment(resized).add(1, 'second').isAfter(moment()) && this.gridApi){
			this.gridApi.sizeColumnsToFit();
		}
		if(searchResults) {
			// this.gridApi.getRows().forEach(row => {
			// 	console.log(row)
			// });
			const { results, total, pages, params } = searchResults;
			if(params && params.successCallback && typeof params.successCallback === 'function') {
				params.successCallback(results, total);
				if(this.gridApi) {
					this.gridApi.sizeColumnsToFit();
					this.gridApi.hideOverlay();
				}
			}
		}
		if(updateUser && this.gridApi) {
			this.gridApi.forEachNode(rowNode => {
				if(rowNode.data && (rowNode.data._id === updateUser._id)) {
					rowNode.setData(updateUser);					
					this.gridApi.setRowData([rowNode]);
					setTimeout(() => {
						this.gridApi.flashCells({rowNodes: [rowNode]});
						//this.gridApi.refreshInfiniteCache();
					});
				}
			});
		}
	}

	getRows = async (params, state) => {
		this.gridApi.showLoadingOverlay();
		// const response = await axios.post(`/api/users/search`, { 
		// 	filterModel: params.filterModel, 
		// 	limit: params.endRow - params.startRow,
		// 	skip: params.startRow, 
		// 	sortModel: params.sortModel
		// });
		this.props.dispatch(searchUsers({ 
			filterModel: params.filterModel, 
			limit: params.endRow - params.startRow,
			skip: params.startRow, 
			sortModel: params.sortModel
		}, params));
		//params.successCallback(response.data.results, response.data.total);		
		//this.gridApi.sizeColumnsToFit();
		//this.gridApi.hideOverlay();
	}	

	// onSortChanged = () => {
	// 	//scroll to selected
	// 	if(this.state.selectedNodes.length) {
	// 		this.gridApi.ensureNodeVisible(this.state.selectedNodes[0]);
	// 		const that = this;
	// 		setTimeout(() => {
	// 			const i = that.state.selectedNodes[0].rowIndex;
	// 			that.gridApi.setInfiniteRowCount(i)
	// 			that.gridApi.ensureIndexVisible(i, 'top');
	// 		})
			
	// 	}
	// }

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.columnApi = params.columnApi;
		const dataSource = {
			rowCount: null, // behave as infinite scroll
			getRows: (params) => { this.getRows(params, this.state) }
		};
		const that = this;
		this.gridApi.setDatasource(dataSource);
		this.gridApi.sizeColumnsToFit();
		//clear previously selected user
		if(this.props.onRowSelected) {
			this.props.onRowSelected([]);
		}
	}

	onRowSelected = (e) => {
		if (this.gridApi) {
			const selectedNodes = this.gridApi.getSelectedNodes();
			const selectedUsers = this.gridApi.getSelectedRows();
			this.setState({
				...this.state,
				selectedNodes,	
				selectedUsers
			}, () => {
				if(this.props.onRowSelected) {
					this.props.onRowSelected(this.state.selectedUsers);
				}
			});
		}
	}

	render() {
		return (
			<div className="ag-theme-balham-dark" style={{ height: '100%', width: '100%' }}>
				<AgGridReact
					rowSelection="single"
					rowDeselection={true}
					suppressCellSelection={true}
					onRowSelected={this.onRowSelected}			
					onGridReady={this.onGridReady}
					onSortChanged={this.onSortChanged}
					defaultColDef={this.state.defaultColDef}
					columnDefs={this.state.columnDefs}
					rowModelType="infinite"
					maxConcurrentDatasourceRequests={1}
				>
				</AgGridReact>			
			</div>
		);
	}
}

export default connect(mapStoreStateToProps)(UsersGrid);