import { combineReducers } from 'redux';

import user from './userReducer';
import player from './playerReducer';
import defense from './defenseReducer';
import geocode from  './geocodeReducer';
import common from './commonReducer';
import events from './eventReducer';

export default combineReducers({
    user,
    common,
    geocode,
    player,
    defense,
    events,
});