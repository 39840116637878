import React from 'react';
import { connect } from 'react-redux';
import history from 'routes/history';
import { Card, Container, Form, Row, Col, InputGroup, Button, ListGroupItem, ListGroup } from 'react-bootstrap';
import LoadingSpinner from 'components/common/LoadingSpinner';

import { getAuthStatus, checkAuthStatus } from 'actions/userActions';
import NewLeagueModal from './leagues/NewLeagueModal';

function mapStoreStateToProps(store) {
  return {
    authenticated: store.user.authenticated,
    authenticating: store.user.authenticating,
    attempted: store.user.attempted,
    check_timestamp: store.user.check_timestamp,
    error: store.user.error,
    user: store.user.user
  }
}

class MyLeaguesHome extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      authenticating: props.authenticating,
      authenticated: props.authenticated,
      restrictedPaths: ['/listings/apply'],
      scoringFields: {
        pass_att: { label: 'Pass Attempts', pts_per: 0 },
        pass_cmp: { label: 'Comp. Passes', pts_per: 0 },
        pass_yds: { label: 'Passing YDs', pts_per: 0 },
        pass_td: { label: 'Passings TDs', pts_per: 0 },
        pass_int: { label: 'Passings INTs', pts_per: 0 },
        pass_sacked: { label: 'Passings INTs', pts_per: 0 },
        //RECIEVING
        targets: { label: 'Target', pts_per: 0 },
        rec: { label: 'Receptions', pts_per: 0 },
        rec_yds: { label: 'Receiving YDs', pts_per: 0 },
        rec_td: { label: 'Receiving TDs', pts_per: 0 },
        //RUSHING
        rush_att: { label: 'Rushing Attempts', pts_per: 0 },
        rush_yds: { label: 'Rushing YDs', pts_per: 0 },
        rush_td: { label: 'Rushing TDs', pts_per: 0 },
        two_pt_md: { label: '2Pt Conv.', pts_per: 0 },
        //FUMBLES
        fumbles: { label: 'Fumbles', pts_per: 0 },
        //DEFENSIVE
        sacks: { label: 'Sacks', pts_per: 0 },
        tackles_solo: { label: 'Tackles', pts_per: 0 },
        qb_hits: { label: 'QB Hits', pts_per: 0 },
        fumbles_forced: { label: 'Forced Fumbles', pts_per: 0 },
        fumbles_rec_td: { label: 'Fumble TD', pts_per: 0 },
        def_int: { label: 'Defensive INT', pts_per: 0 },
        def_int_yds: { label: 'Defensive INT YDs', pts_per: 0 },
        def_int_td: { label: 'Defensive INT TDs', pts_per: 0 },
        //SPECIAL TEAMS
        kick_ret_yds: { label: 'Kickoff Return YDs', pts_per: 0 },
        kick_ret_td: { label: 'Kickoff Return TDs', pts_per: 0 },
        punt_ret_yds: { label: 'Punt Return YDs', pts_per: 0 },
        punt_ret_td: { label: 'Punt Return TDs', pts_per: 0 },
        //KICKING
        xpm: { label: 'Extra Points Made', pts_per: 0 },
        xpa: { label: 'Extra Points Attempted', pts_per: 0 },
        fgm: { label: 'FG Made', pts_per: 0 },
        fga: { label: 'FG Attempted', pts_per: 0 }
      },
      selectedField: 'pass_att',
      enteredPointValue: null
    };
  }

  componentWillMount() {
    this.props.dispatch(checkAuthStatus());
  }

  componentWillReceiveProps(props) {
    const { authenticated, authenticating, attempted, user, error } = props;
    if (!authenticated && !authenticating && !attempted) {
      this.props.dispatch(getAuthStatus());
      return;
    }
    if (error && this.state.restrictedPaths.includes(window.location.pathname)) {
      window.location.href = '/login';
    }
    this.setState({
      ...this.state,
      user,
      authenticated,
      authenticating
    });
  }

  logout = () => {
    window.location.href = '/api/auth/logout';
  }

  buildSettingsOptions = () => {
    const { scoringFields } = this.state;
    const options = [];
    Object.keys(scoringFields).forEach(field => {
      options.push(<option value={field}>{scoringFields[field].label}</option>);
    });
    return options;
  }

  handleSettingOptionChange = (e) => {
    this.setState({ ...this.state, selectedField: e.target.value });
  }

  onValueChange = (field, e) => {
    const { scoringFields } = this.state;
    scoringFields[field].pts_per = e.target.valueAsNumber;
    this.setState({ ...this.state, scoringFields });
  }

  saveSetting = () => {
    this.setState({ ...this.state });
  }

  addLeague = () => {
    this.setState({...this.state, showNewLeageuModal: true });
  }

  onLeagueModalClose = (newLeague) => {
    this.setState({...this.state, showNewLeageuModal: false }, () =>{
      if(newLeague) {
        //this.dispatch...
      }
    });
  }

  renderUserLeagues = () => {
    const { user } = this.state;
    if(!user) return null;
    user.leagues = user.leagues ? user.leagues : [];
    const userLeagues = [];
    user.leagues.forEach(league => {
      userLeagues.push(<ListGroupItem>{league.name}</ListGroupItem>)
    });
    if(!userLeagues.length){
      userLeagues.push(<ListGroupItem disabled>No Leagues Found</ListGroupItem>)
    }
    return userLeagues;
  }

  render() {
    const { authenticated, user } = this.state;
    let name = '';
    if (user) name = `${user.email}`
    const enteredValue = this.state.scoringFields[this.state.selectedField] ? this.state.scoringFields[this.state.selectedField].pts_per : 0;
    return (
      user ?
      <Container className="mt-5 pt-2">
        { this.state.showNewLeageuModal ? <NewLeagueModal show={this.state.showNewLeageuModal} close={this.onLeagueModalClose} /> : null }
        <Card className="app-card shadow">
          <Card.Header>
            <i className="mdi mdi-trophy-outline mr-1" /> My Leagues
          </Card.Header>
          <Card.Body>
            <Row>
              <Col xs="12">
                <ListGroup>
                  {this.renderUserLeagues()}
                </ListGroup>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs="12" style={{textAlign: 'right'}}>
                <Button title={"Add New Leage"} onClick={e => this.addLeague()}><i className="fa fa-plus mr-1" /> Add League</Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
      : <LoadingSpinner />
    );
  }
}

export default connect(mapStoreStateToProps)(MyLeaguesHome);