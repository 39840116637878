import axios from 'axios';
export function showToast(data) {
    return function(dispatch) { 
          dispatch({type: 'SHOW_TOAST', payload: data });
    }
}


export function getBuildInfo() {
    return function(dispatch) {
      axios.get('/api/buildinfo')
        .then(response => {
          dispatch({type: 'BUILD_INFO_SUCCESS', payload: response.data });
        })
        .catch(error => {
          dispatch({type: 'BUILD_INFO_SUCCESS_ERROR', payload: error.response.data });
        });
    }
  }