export default function reducer(state = {
    fetching: false
}, action) {
  switch (action.type) {
    case 'GET_ALL_DEFENSES': {
      return {
        ...state,
        fetching: true
      }
    }
    case 'GET_ALL_DEFENSES_SUCCESS': {
      const { data } = action.payload;
      return {
        ...state,
        fetching: false,
        defenses: data
      }
    }
    case 'GET_ALL_DEFENSES_ERROR': {
      return {
        ...state,
        fetching: false
      }
    }

    default: {
      return { ...state }
    }
  }
}