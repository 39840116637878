

import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import moment from 'moment';
import { utils } from 'common';
import { AgGridReact } from 'ag-grid-react';
import { searchPlayers } from '../../../actions/playerActions';

const mapStoreStateToProps = (store) => {
	return {
		searchResults: store.player.searchResults,
		updatePlayer: store.player.updatePlayer
	};
}

class PlayersGrid extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			darkmode: props.darkmode,
			selectedPlayers: [],
			selectedNodes: [],
			defaultColDef: {
				sortable: true,
				filter: true,
				resizable: true,
				enableCellChangeFlash: true
			},
			columnDefs: [
				{ 
					headerName: "Player", 
					field: "name",
					filter: 'agTextColumnFilter'
				},
				{ 
					headerName: "Position", 
					field: "position",
					filter: "agTextColumnFilter",
					width: '25'
				},
				{ 
					headerName: "Team", 
					field: "team",
					filter: "agTextColumnFilter"
				},
				{ 
					headerName: "Height", 
					field: "height",
					filter: "agNumberColumnFilter",
					valueFormatter: (params) => {
						return params.value && !isNaN(parseInt(params.value)) ? `${Math.floor(parseInt(params.value)/12)} ft ${parseInt(params.value)%12 > 0 ? `${parseInt(params.value)%12} in` : ''}` : '';
					}
				},
				{ 
					headerName: "Weight", 
					field: "weight",
					filter: "agNumberColumnFilter",
					valueFormatter: (params) => {
						return params.value && !isNaN(parseInt(params.value)) ? `${parseInt(params.value)} lbs` : '';
					}
				},
				{ 
					headerName: "Age", 
					field: "birthdate",
					filter: "agNumberColumnFilter",
					valueFormatter: (params) => {
						return Math.abs(moment(params.value).diff(moment(), 'years'));
					}
				},
				{ 
					headerName: "Experience", 
					field: "seasonsPlayed",
					filter: "agNumberColumnFilter",
					valueFormatter: (params) => {
						return params.value > 0 ? `${params.value} ${params.value > 1 ? 'years' : 'year'}` : 'Rookie';
					}
				},
				{ 
					headerName: "Games", 
					field: "gamesPlayed",
					filter: "agNumberColumnFilter"
				},
				{ 
					headerName: "Fantasy Points", 
					field: "fantasyPoints",
					filter: "agNumberColumnFilter",
					sort: "desc"
				},
				{ 
					headerName: "Last Update", 
					field: "updatedAt",
					filter: "agDateColumnFilter",
					valueFormatter: (params) => {
						return moment(params.value).fromNow();
					}
				}
			],
			players: [],
			pages: 0,
			total: 0,
			fetching: true
		}
	}

	componentWillReceiveProps(props) {
		const { resized, searchResults, updatePlayer, darkmode } = props;
		if(resized && moment(resized) && moment(resized).add(1, 'second').isAfter(moment()) && this.gridApi){
			setTimeout(() => {
				this.gridApi.sizeColumnsToFit();
			});
		}
		if(searchResults) {
			// this.gridApi.getRows().forEach(row => {
			// 	console.log(row)
			// });
			const { results, total, pages, params } = searchResults;
			if(params && params.successCallback && typeof params.successCallback === 'function') {
				params.successCallback(results, total);
				if(this.gridApi) {
					this.gridApi.sizeColumnsToFit();
					this.gridApi.hideOverlay();
				}
			}
		}
		if(updatePlayer && this.gridApi) {
			this.gridApi.forEachNode(rowNode => {
				if(rowNode.data && (rowNode.data._id === updatePlayer._id)) {
					rowNode.setData(updatePlayer);					
					this.gridApi.setRowData([rowNode]);
					setTimeout(() => {
						this.gridApi.flashCells({rowNodes: [rowNode]});
						//this.gridApi.refreshInfiniteCache();
					});
				}
			});
		}
	}

	getRows = async (params, state) => {
		this.gridApi.showLoadingOverlay();
		// const response = await axios.post(`/api/players/search`, { 
		// 	filterModel: params.filterModel, 
		// 	limit: params.endRow - params.startRow,
		// 	skip: params.startRow, 
		// 	sortModel: params.sortModel
		// });
		this.props.dispatch(searchPlayers({ 
			filterModel: params.filterModel, 
			limit: params.endRow - params.startRow,
			skip: params.startRow, 
			sortModel: params.sortModel
		}, params));
		//params.successCallback(response.data.results, response.data.total);		
		//this.gridApi.sizeColumnsToFit();
		//this.gridApi.hideOverlay();
	}	

	// onSortChanged = () => {
	// 	//scroll to selected
	// 	if(this.state.selectedNodes.length) {
	// 		this.gridApi.ensureNodeVisible(this.state.selectedNodes[0]);
	// 		const that = this;
	// 		setTimeout(() => {
	// 			const i = that.state.selectedNodes[0].rowIndex;
	// 			that.gridApi.setInfiniteRowCount(i)
	// 			that.gridApi.ensureIndexVisible(i, 'top');
	// 		})
			
	// 	}
	// }

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.columnApi = params.columnApi;
		const dataSource = {
			rowCount: null, // behave as infinite scroll
			getRows: (params) => { this.getRows(params, this.state) }
		};
		this.gridApi.setDatasource(dataSource);
		this.gridApi.sizeColumnsToFit();
		//clear previously selected player
		if(this.props.onRowSelected) {
			this.props.onRowSelected([]);
		}
	}

	onRowSelected = (e) => {
		if (this.gridApi) {
			const _gridApi = this.gridApi;
			const selectedNodes = _gridApi.getSelectedNodes();
			const selectedPlayers = _gridApi.getSelectedRows();
			this.setState({
				...this.state,
				selectedNodes,	
				selectedPlayers
			}, () => {
				if(this.props.onRowSelected) {
					this.props.onRowSelected(this.state.selectedPlayers);
				}
				setTimeout(() => { _gridApi.sizeColumnsToFit(); })
			});
		}
	}

	render() {
		return (
			<div className={`ag-theme-balham${this.props.darkmode ? '-dark' : ''}`} style={{ height: '100%', width: '100%' }}>
				<AgGridReact
					rowSelection="single"
					rowDeselection={true}
					suppressCellSelection={true}
					suppressNavigable={true}
					cellClass="no-border"
					onRowSelected={this.onRowSelected}			
					onGridReady={this.onGridReady}
					onSortChanged={this.onSortChanged}
					defaultColDef={this.state.defaultColDef}
					columnDefs={this.state.columnDefs}
					rowModelType="infinite"
					maxConcurrentDatasourceRequests={1}
				>
				</AgGridReact>			
			</div>
		);
	}
}

export default connect(mapStoreStateToProps)(PlayersGrid);